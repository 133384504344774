import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import carsData from '../carsData.json';
import './CarDetailsPage.css';

export const CarDetailsPage = () => {
  const { detailsUrl } = useParams();
  const car = carsData.find((c) => c.detailsUrl === detailsUrl);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!car) {
    return <div>Car not found!</div>;
  }

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === car.imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? car.imageUrls.length - 1 : prevIndex - 1
    );
  };


  return (
    <div className="CarDetailsPage">
      <div className="CarDetailsImageContainer">
        <div className="CarDetailsImage">
          <img src={car.imageUrls[currentImageIndex]} alt={`${car.make} ${car.model}`} />
        </div>
        <div className="ImageNavigationButtons">
          <button onClick={prevImage} className="CarouselButton">Previous</button>
          <button onClick={nextImage} className="CarouselButton">Next</button>
        </div>
      </div>
      <div className="CarDetailsPageDetails">
        <h2>{car.make} - {car.model}</h2>
        <p>Year: {car.year}</p>
        <p>Miles: {car.mileage}</p>
        {car.extradetails && car.extradetails.length > 0 && (
          <ul>
            {car.extradetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        )}
        <Link to="/contact">Contact Us</Link>
      </div>
    </div>
  );
};
