import React from 'react';
import { Link } from 'react-router-dom';

import { Card } from '../components/Card';
import carsData from '../carsData.json';

import './InventoryPage.css';

export const InventoryPage = () => {
  return (
    <div>
      <section className="HeroSection">
        <h1>Looking for a specific car? </h1>
        <p>Contact us below and we'll help you find it!</p>
        <Link to="/contact" className="HeroCTA">Contact Us</Link>
      </section>
      <div className="InventoryPage">
        {carsData.map((car) => (
          <Card key={car.id} car={car} layout="vertical" />
          ))}
      </div>
    </div>
  );
}
