import React from 'react';
import './App.css';

function Footer() {
  return (
    <footer className="App-footer">
      <p>Phone: (956) 857-4887</p>
      <p>Address: 2719 E Ash St, Laredo, TX 78043</p>
    </footer>
  );
}

export default Footer;
