import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css';

export const ContactPage = () => {
  const [formStatus, setFormStatus] = useState({ message: '', status: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const form = useRef();

  useEffect(() => {
    if (form.current) {
      const checkFormValidity = () => {
        setIsFormValid(form.current.checkValidity());
      };
      checkFormValidity();
      form.current.addEventListener('change', checkFormValidity);

      return () => {
        if (form.current) {
          form.current.removeEventListener('change', checkFormValidity);
        }
      };
    }
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('service_ojfncya', 'template_g9rzb9b', form.current, 'bI66acZQh9mqiekxw')
      .then((result) => {
          console.log(result.text);
          setFormStatus({ message: 'Your message has been sent successfully, our team will reach out to you as soon as possible.', status: 'success' });
          form.current.reset();
          setIsSubmitting(false);
          setIsFormValid(false); 
      }, (error) => {
          console.log(error.text);
          setFormStatus({ message: 'Failed to send the message, please try again or call us directly at (956) 857-4887.', status: 'error' });
          setIsSubmitting(false);
      });
  };

  return (
    <div className="contact-page">
      {formStatus.message && (
        <div className={`message ${formStatus.status === 'success' ? 'success' : 'error'}`}>
          {formStatus.message}
        </div>
      )}
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <h3>Contact Details</h3>
        <input type="text" name="firstName" placeholder="First Name*" required />
        <input type="text" name="lastName" placeholder="Last Name*" required />
        <input type="text" name="email" placeholder="Email Address*" required />
        <input type="text" name="phone" placeholder="Phone Number*" required />
        <h3>Tell Us About the Car You're Looking For</h3>
        <input type="text" name="Make" placeholder="Make" />
        <input type="text" name="Model" placeholder="Model" />
        <input type="text" name="Year" placeholder="Year" />
        <input type="text" name="Mileage" placeholder="Mileage" />

        <textarea name="message" placeholder="Additional Details" />
        <label htmlFor="tradeIn">Trade In
          <input type="checkbox" name="tradeIn" value="YES"/>
        </label>
        <label htmlFor="financing">Financing
          <input type="checkbox" name="financing" value="YES"/>
        </label>
        <input type="text" name="Budget" placeholder="Budget" />
        <button type="submit" className="submit-btn" disabled={isSubmitting || !isFormValid}>Send</button>
      </form>
    </div>
  );
};
