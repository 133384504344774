import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../components/Card';
import carsData from '../carsData.json';

import './HomePage.css';

export const HomePage = () => {
  return (
    <div className="HomePage">
      <section className="HeroSection">
        <h1>Welcome to Salce Auto Sales</h1>
        <p>Working with Laredoans for 5 years</p>
        <p>In House Financing available to all</p>
        <Link to="/inventory" className="HeroCTA">View Complete Inventory</Link>
      </section>

      <section className="FeaturedCars">
        <div className="CardContainer">
          {carsData.slice(0, 4).map((car) => (
            <Card key={car.id} car={car} layout="horizontal" />
          ))}
        </div>
      </section>
    </div>
  );
};
