import React from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo_close_crop.png';

function Header() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="Salce Auto Sales Logo" />
      <nav className="header-nav">
        <Link to="/">Home</Link>
        <Link to="/inventory">Inventory</Link>
        <Link to="/contact">Contact Us</Link>
      </nav>
    </header>
  );
}

export default Header;