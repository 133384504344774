import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css'; // Assuming you have a separate CSS file for the card

export const Card = ({ car, layout }) => {
  const isMobile = window.innerWidth < 768;
  const cardClass = `CarCard ${isMobile ? 'vertical' : layout}`;

  return (
    <div className={cardClass}>
      <div className="CarImage">
        <img src={car.imageUrls[0]} alt={`${car.make} ${car.model}`} />
      </div>
      <div className="CarDetails">
        <h3>{car.make} - {car.model}</h3>
        <p>Year: {car.year}</p>
        <p>Miles: {car.mileage}</p>
        <div className="CarActions">
          <Link to={`/inventory/${car.detailsUrl}`}>View More Details</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
    </div>
  );
}
