import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { InventoryPage } from './pages/InventoryPage';
import { ContactPage } from './pages/ContactPage';
import { CarDetailsPage } from './pages/CarDetailsPage';
import Header from './Header';
import Footer from './Footer';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <main className="App-main">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/inventory" element={<InventoryPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/inventory/:detailsUrl" element={<CarDetailsPage />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
